<template>
  <div id="app">
    <Home/>
  </div>
</template>

<script>
import Home from './components/Home.vue'

export default {
  name: 'App',
  components: {
    Home
  }
}
</script>

<style>
html {
  font-size: 16px;
}
@media only screen and (max-width: 992px) {
  html {
    font-size: 14px !important;
  }
}
@media only screen and (max-width: 768px) {
  html {
    font-size: 12px !important;
  }
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #fff;
}
body {
  margin: 0;
  padding: 0;
  background: #000;
  min-width: 360px;
}
.swiper-pagination-bullet {
  background: #fff !important;
  opacity: 1 !important;
}
.swiper-pagination-bullet-active {
  --swiper-theme-color: #C91721 !important;
  /* --swiper-pagination-color: #fff; */
}
.swiper-pagination-bullet-active {
  background: #C91721 !important;
}
.swiperLeft {
    width: 4vw !important;
    height: 4vw !important;
    background-image: url('./assets/images/left.png');
    background-size: 100% 100%;
    left: -4.5vw !important;
}
.swiper-button-prev{
  --swiper-navigation-color: rgba(255, 255, 255, 0) !important;
}
.swiperRight {
    width: 4vw !important;
    height: 4vw !important;
    background-image: url('./assets/images/right.png');
    background-size: 100% 100%;
    right: -4.5vw !important;
}
.swiper-button-next{
  --swiper-navigation-color: rgba(255, 255, 255, 0) !important;
}
.swiperLeft2 {
    width: 10vw !important;
    height: 10vw !important;
    background-image: url('./assets/images/left.png');
    background-size: 100% 100%;
    top: 55vw !important;
    left: 0px !important;

}
.swiperRight2 {
    width: 10vw !important;
    height: 10vw !important;
    background-image: url('./assets/images/right.png');
    background-size: 100% 100%;
    top: 55vw !important;
    right: 0px !important;
}
</style>
