import Vue from 'vue'
import App from './App.vue'
import AOS from "aos"
import "../node_modules/aos/dist/aos.css"
import 'swiper/css/swiper.css'

Vue.use(AOS)
Vue.config.productionTip = false

new Vue({
  created() {
    AOS.init();
  },
  render: h => h(App),
}).$mount('#app')
