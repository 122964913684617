<template>
    <div class="home">
        <nav class="nav">
            <img class="logo" src="https://deansel-bucket.oss-cn-shanghai.aliyuncs.com/gametrigger/logo.png" alt="">
            <div class="navbox">
                <div :class="{'navitem': true }" style="display: flex;">
                    <div :class="{'navitem': true, 'navitem_active2': now == 'chinese' }" @click="switchF('chinese')">中文</div>
                    <div style="width: 1px; height: 16px; margin-top: 4px; margin-left: 5px; margin-right: 5px; border-left: 1px solid #fff;"></div>
                    <div :class="{'navitem': true, 'navitem_active2': now == 'english' }"  @click="switchF('english')">EN</div>
                </div>
                <div :class="{'navitem': true, 'navitem_active': navIndex === 0 }" @click="onChangeNav(0)">{{ language.nav.a }}</div>
                <div :class="{'navitem': true, 'navitem_active': navIndex === 1 }" @click="onChangeNav(1)">{{ language.nav.b }}</div>
                <div :class="{'navitem': true, 'navitem_active': navIndex === 2 }" @click="onChangeNav(2)">{{ language.nav.c }}</div>
                <div :class="{'navitem': true, 'navitem_active': navIndex === 3 }" @click="onChangeNav(3)">{{ language.nav.d }}</div>
                <div :class="{'navitem': true, 'navitem_active': navIndex === 4 }" @click="onChangeNav(4)">{{ language.nav.e }}</div>
            </div>
        </nav>
        <nav class="nav_mobile">
            <img class="nav_mobile_logo" src="https://deansel-bucket.oss-cn-shanghai.aliyuncs.com/gametrigger/logo_narrow.png" alt="">
            <div class="navIcon" @click="onOpenSideNav">
                <img class="navIconImg" src="https://deansel-bucket.oss-cn-shanghai.aliyuncs.com/gametrigger/icon_item.png" alt="">
            </div>
            <div class="sidenavContainer" id="sidenavContainer" @touchmove.prevent v-show="showSideNav" @click="onCloseSideNav">
                <div class="sidenav">
                    <img class="side_logo" src="https://deansel-bucket.oss-cn-shanghai.aliyuncs.com/gametrigger/logo.png" alt="">
                    <div class="sidenavBox">
                        <div :class="{'navitem': true, 'sidenavitem_active': navIndex === 0 }" @click="onChangeSideNav(0)">{{ language.nav.a }}</div>
                        <div :class="{'navitem': true, 'sidenavitem_active': navIndex === 1 }" @click="onChangeSideNav(1)">{{ language.nav.b }}</div>
                        <div :class="{'navitem': true, 'sidenavitem_active': navIndex === 2 }" @click="onChangeSideNav(2)">{{ language.nav.c }}</div>
                        <div :class="{'navitem': true, 'sidenavitem_active': navIndex === 3 }" @click="onChangeSideNav(3)">{{ language.nav.d }}</div>
                        <div :class="{'navitem': true, 'sidenavitem_active': navIndex === 4 }" @click="onChangeSideNav(4)">{{ language.nav.e }}</div>
                    </div>
                </div>
            </div>
            <div :class="{'navitem': true }" style="display: flex; position: fixed; right: 10px; top: 30px; line-height: 20px; z-index: 2;">
                <div :class="{'navitem': true, 'navitem_active2': now == 'chinese' }" @click="switchF('chinese')">中文</div>
                <div style="width: 1px; height: 16px; margin-top: 2px; margin-left: 5px; margin-right: 5px; border-left: 1px solid #fff;"></div>
                <div :class="{'navitem': true, 'navitem_active2': now == 'english' }"  @click="switchF('english')">EN</div>
            </div>
        </nav>
        <div class="page">
            <div class="section bg_home" id="section_0">
                <img class="home_logo" data-aos="fade-down" data-aos-duration="500" src="https://deansel-bucket.oss-cn-shanghai.aliyuncs.com/gametrigger/home_logo.png" alt="">
                <img class="home_title" data-aos="fade-down" data-aos-duration="500" data-aos-delay="200" src="https://deansel-bucket.oss-cn-shanghai.aliyuncs.com/gametrigger/home_title.png" alt="">
                <div class="home_words">
                    <img data-aos="fade-left" data-aos-duration="500" data-aos-delay="400" src="https://deansel-bucket.oss-cn-shanghai.aliyuncs.com/gametrigger/home_word1.png" alt="">
                    <img data-aos="fade-left" data-aos-duration="500" data-aos-delay="600" src="https://deansel-bucket.oss-cn-shanghai.aliyuncs.com/gametrigger/home_word_line.png" alt="">
                    <img data-aos="fade-left" data-aos-duration="500" data-aos-delay="800" src="https://deansel-bucket.oss-cn-shanghai.aliyuncs.com/gametrigger/home_word2.png" alt="">
                    <img data-aos="fade-left" data-aos-duration="500" data-aos-delay="1000" src="https://deansel-bucket.oss-cn-shanghai.aliyuncs.com/gametrigger/home_word_line.png" alt="">
                    <img data-aos="fade-left" data-aos-duration="500" data-aos-delay="1200" src="https://deansel-bucket.oss-cn-shanghai.aliyuncs.com/gametrigger/home_word3.png" alt="">
                </div>
            </div>
            <div class="section" id="section_1">
                <div class="title">
                    <div class="title_top" data-aos="fade-down" data-aos-duration="500">
                        <img src="https://deansel-bucket.oss-cn-shanghai.aliyuncs.com/gametrigger/icon_title.png" alt="">
                        <div>{{ language.section_1.title }}</div>
                        <img src="https://deansel-bucket.oss-cn-shanghai.aliyuncs.com/gametrigger/icon_title.png" alt="">
                    </div>
                    <div data-aos="fade-down" data-aos-duration="500" data-aos-delay="200" class="title_bottom">THEME</div>
                </div>
                <div class="content_reverse" >
                    <img data-aos="fade-down" data-aos-duration="500" class="content_img" src="https://deansel-bucket.oss-cn-shanghai.aliyuncs.com/gametrigger/investment.jpg" alt="">
                    <div class="textbox">
                        <div class="text_title" data-aos="fade-down" data-aos-duration="500" data-aos-delay="200"><span class="spot"> </span><span>{{ language.section_1.text_title1 }}</span></div>
                        <div class="text" data-aos="fade-down" data-aos-duration="500" data-aos-delay="200">{{ language.section_1.text1 }}</div>
                        <div class="text_title" data-aos="fade-down" data-aos-duration="500" data-aos-delay="400"><span class="spot"> </span><span>{{ language.section_1.text_title2 }}</span></div>
                        <div class="text" data-aos="fade-down" data-aos-duration="500" data-aos-delay="400">{{ language.section_1.text2 }}</div>
                        <div class="text_title" data-aos="fade-down" data-aos-duration="500" data-aos-delay="600"><span class="spot"> </span><span>{{ language.section_1.text_title3 }}</span></div>
                        <div class="text" data-aos="fade-down" data-aos-duration="500" data-aos-delay="600">{{ language.section_1.text3 }}</div>
                    </div>
                </div>
            </div>
            <div class="section" id="section_2">
                <div class="title">
                    <div class="title_top" data-aos="fade-down" data-aos-duration="500">
                        <img src="https://deansel-bucket.oss-cn-shanghai.aliyuncs.com/gametrigger/icon_title.png" alt="">
                        <div>{{ language.section_2.title }}</div>
                        <img src="https://deansel-bucket.oss-cn-shanghai.aliyuncs.com/gametrigger/icon_title.png" alt="">
                    </div>
                    <div data-aos="fade-down" data-aos-duration="500" data-aos-delay="200" class="title_bottom">ABOUT US</div>
                </div>
                <div class="content">
                    <img data-aos="fade-down" data-aos-duration="500" data-aos-delay="200" class="content_img" src="https://deansel-bucket.oss-cn-shanghai.aliyuncs.com/gametrigger/aboutus.jpg" alt="">
                    <div class="textbox">
                        <div class="text_title_2" data-aos="fade-down" data-aos-duration="500">{{ language.section_2.text_title }}</div>
                        <div class="text" data-aos="fade-down" data-aos-duration="500" data-aos-delay="200">{{ language.section_2.text1 }}</div>
                        <div class="text" data-aos="fade-down" data-aos-duration="500" data-aos-delay="400">{{ language.section_2.text2 }}</div>
                    </div>
                </div>
            </div>
            <div class="section" id="section_3">
                <div class="title">
                    <div class="title_top" data-aos="fade-down" data-aos-duration="500">
                        <img src="https://deansel-bucket.oss-cn-shanghai.aliyuncs.com/gametrigger/icon_title.png" alt="">
                        <div>{{ language.nav.d }}</div>
                        <img src="https://deansel-bucket.oss-cn-shanghai.aliyuncs.com/gametrigger/icon_title.png" alt="">
                    </div>
                    <div data-aos="fade-down" data-aos-duration="500" data-aos-delay="200" class="title_bottom">PORTFOLIO</div>
                </div>
                <div class="content_reverse_pc" data-aos="fade-down" data-aos-duration="500" data-aos-delay="200">
                    <div class="swiper-container" style="width: 75vw; height: 25.6vw; background-color: antiquewhite; position: relative;" >
                        <div class="swiper-wrapper">
                            <div class="swiper-slide swiper_for" v-for="(item, index) in language.section_3" :style="{ backgroundImage: `url(${item.pcImg})`}" v-bind:key="index">
                                <div class="swiper_L">
                                    <div class="swiper_title">{{ item.title }}</div>
                                    <div class="swiper_text">{{ item.text }}</div>
                                    <div style="text-align: left;">
                                        <div class="swiper_link" v-for="(item2, index) in item.link" v-bind:key="index" :style="{ backgroundImage: `url(${item2.icon})`}">
                                            <a :href="item2.url" target="_blank" style="display: block; width: 100%; height: 100%;"></a>
                                        </div>
                                    </div>
                                </div>
                                <div class="swiper_R" :style="{ backgroundImage: `url(${item.pcImg})`}"></div>
                            </div>
                        </div>
                        <div class="swiper-pagination"></div>
                    </div>
                    <div class="swiper-button-prev swiperLeft"></div>
                    <div class="swiper-button-next swiperRight"></div>
                </div>
                <div class="content_reverse_mobile">
                    <div class="swiper-container" style="width: 100vw; height: 180vw;">
                        <div class="swiper-wrapper">
                            <div class="swiper-slide swiper_for2" v-for="(item, index) in language.section_3" v-bind:key="index">
                                <div class="swiper_R2" :style="{ backgroundImage: `url(${item.mobileImg})`}"></div>
                                <div class="swiper_L2">
                                    <div class="swiper_title">{{ item.title }}</div>
                                    <div class="swiper_text">{{ item.text }}</div>
                                    <div style="text-align: left; margin-top: 2vw;">
                                        <div class="swiper_link2" v-for="(item2, index) in item.link" v-bind:key="index" :style="{ backgroundImage: `url(${item2.icon})`}">
                                            <a :href="item2.url" target="_blank" style="display: block; width: 100%; height: 100%;"></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="swiper-pagination" style="position: absolute; top: 112vw; height: 20px;"></div>
                        <div class="swiper-button-prev swiperLeft2"></div>
                        <div class="swiper-button-next swiperRight2"></div>
                    </div>
                    
                </div>
            </div>
            <div class="section" id="section_4">
                <div class="title">
                    <div class="title_top" data-aos="fade-down" data-aos-duration="500">
                        <img src="https://deansel-bucket.oss-cn-shanghai.aliyuncs.com/gametrigger/icon_title.png" alt="">
                        <div>{{ language.section_4.title }}</div>
                        <img src="https://deansel-bucket.oss-cn-shanghai.aliyuncs.com/gametrigger/icon_title.png" alt="">
                    </div>
                    <div data-aos="fade-down" data-aos-duration="500" data-aos-delay="200" class="title_bottom">CONTACT US</div>
                </div>
                <div class="contact_box" data-aos="fade-down" data-aos-duration="500" data-aos-delay="200">
                    <div class="left_box">
                        <div>Let's start your engine</div>
                        <img class="icon_engine" src="https://deansel-bucket.oss-cn-shanghai.aliyuncs.com/gametrigger/icon_engine.png" alt="">
                    </div>
                    <div class="middle_line"></div>
                    <div class="right_box" data-aos="fade-down" data-aos-duration="500" data-aos-delay="200">
                        <div class="contact_title">{{ language.section_4.text1 }}</div>
                        <a class="mail" href="mailto:dream@gametriggervc.com">dream@gametriggervc.com</a>
                        <div class="contact_title">{{ language.section_4.text2 }}</div>
                        <a class="mail" href="mailto:jobs@gametriggervc.com">jobs@gametriggervc.com</a>
                    </div>
                    <div class="left_out_border"></div>
                    <div class="right_out_border"></div>
                </div>
                <div class="bottom" data-aos="fade-down" data-aos-duration="500">
                    <div>Copyright ©2020 上海扳机企业管理有限公司/GameTrigger ALL Rights Reserved. </div>
                    <a style="text-decoration: none; color: rgba(255, 255, 255, 0.5);" href="https://beian.miit.gov.cn">沪ICP备2020026423号-1</a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Swiper from 'swiper';
import { request } from '@/xhr';

export default {
    data () {
        return {
            navIndex: 0,
            offsetTops: [],
            scrollItems: [],
            showSideNav: false,
            swiper: null,
            now: 'chinese',
            language: {},
            languageData: {
                chinese: {
                    nav: {
                        a: '首页',
                        b: '投资主题',
                        c: '关于我们',
                        d: '投资组合',
                        e: '联系我们'
                    },
                    section_1: {
                        title: '投资主题',
                        text_title1: '开发者导向',
                        text1: '希望团队热爱游戏，具备纯正的开发者气质，亲自决定作品的起点和未来走向。同时核心成员又能协作互补，做彼此成就的长期伙伴。',
                        text_title2: '明确的理念',
                        text2: '希望作品具备独特的创作理念，能够给玩家带来惊喜，或许是难以忘怀的叙事演出，或许是体验心流的玩法机制，或许是别具一格的美术视听，或许还有更多的精妙设计。',
                        text_title3: '丰富的类型',
                        text3: '不设限任何一种游戏类型，手机端、PC端、Console全平台项目均会关注，抽卡Gacha、买断Premium等不同的商业模式我们都欢迎。'
                    },
                    section_2: {
                        title: '关于我们',
                        text_title: 'Game Trigger',
                        text1: 'Game Trigger（游戏扳机）是一家新兴的风险投资机构，但更多时候我们自认为是恰好选择了投资行业的游戏爱好者。Game Trigger致力于挖掘各类有趣、优秀的ACG小众文化和产品，并且我们非常愿意分享自己的时间、经验、资金帮助合作伙伴成长。',
                        text2: '我们始终对“第九艺术”抱有着极大的信心和热忱，希望能与同样热衷文化的创业者、制作人，一起构建与众不同的世界。'
                    },
                    section_3: {},
                    section_4: {
                        title: '联系我们',
                        text1: '项目资料/想法可投递至：',
                        text2: '招聘简历可投递至：'
                    }
                },
                english: {
                    nav: {
                        a: 'HOME',
                        b: 'THEME',
                        c: 'ABOUT US',
                        d: 'PORTFOLIO',
                        e: "CONTACT US"
                    },
                    section_1: {
                        title: 'THEME',
                        text_title1: 'Finding industry talents',
                        text1: 'We invest in developers that have a clear passion for video games, and take their own initiatives in what kind of products they would like to deliver. Making video game is a team’s work. We believe in developers who can complement each other and be long-term partners.',
                        text_title2: 'Searching for great ideas',
                        text2: 'We invest in games with unique creative concepts. It could be an unforgettable narrative performance, a hooking gameplay experience, or an impressive artistic expression. Anything that brings surprise to the players deserves to be made.',
                        text_title3: 'Our targets',
                        text3: 'There are no limits. We pay attention to games in all platforms and with all business models.'
                    },
                    section_2: {
                        title: 'ABOUT US',
                        text_title: 'Game Trigger',
                        text1: 'We identify ourselves as the leading gaming fund in China that support exceptional developers. We are devoted to explore the frontier of the creative industry and cultural opportunities that are not widely recognized by the public but has the potential to become the mainstream.',
                        text2: 'GameTrigger is also willing to share their business expertise, time and capital with video game developers to help them fully focus on making wonderful games.'
                    },
                    section_3: {},
                    section_4: {
                        title: 'CONTACT US',
                        text1: 'Tell us about your game:',
                        text2: 'Join our team:'
                    }
                },
            },
            section_3: {
                chinese: [
                    {
                        title: '黑暗世界：因与果',
                        text: '黑暗世界：因与果 是一款第一人称扮演心理恐怖游戏。作为一名Roam干员，你将置身于不同的时间里，漫步在他人的思维世界。揭开事件真相，并且努力去找到这一切起因的源头。游戏由月壤工作室（EN:POLLARD STUDIO)开发，团队成员曾效力与参与《杀戮地带》《战神》《黑相集》《地平线：西部禁域》《COD》等.并负责美术,策划,技术,绑定等内容。',
                        pcImg: 'http://deansel-bucket.oss-cn-shanghai.aliyuncs.com/gametrigger/swiperImg/karma_shu.jpg',
                        mobileImg: 'http://deansel-bucket.oss-cn-shanghai.aliyuncs.com/gametrigger/swiperImg/karma_heng.jpg',
                        link: [
                            {
                                icon: 'http://deansel-bucket.oss-cn-shanghai.aliyuncs.com/gametrigger/swiperImg/bilibili.png',
                                url: 'https://space.bilibili.com/211072?spm_id_from=333.337.search-card.all.click'
                            },
                            {
                                icon: 'http://deansel-bucket.oss-cn-shanghai.aliyuncs.com/gametrigger/swiperImg/steam.png',
                                url: 'https://store.steampowered.com/app/1376200/The_Dark_World_KARMA/'
                            },
                            {
                                icon: 'http://deansel-bucket.oss-cn-shanghai.aliyuncs.com/gametrigger/swiperImg/youtobe.png',
                                url: 'https://www.youtube.com/watch?v=5Ga9LF2dFE0'
                            }
                        ]
                    },
                    {
                        title: '重返未来：1999',
                        text: '重返未来：1999 是一款由深蓝互动开发的20世纪神秘学题材的策略RPG。穿梭于1900~2000年各个时代，帮助不同国家和年代的神秘学家逃离“暴雨”，探寻世界的真相。尽情享受全流程电影化的英语演出、复古风潮的美学。',
                        pcImg: 'http://deansel-bucket.oss-cn-shanghai.aliyuncs.com/gametrigger/swiperImg/reverse_heng.jpg',
                        mobileImg: 'http://deansel-bucket.oss-cn-shanghai.aliyuncs.com/gametrigger/swiperImg/reverse_shu.jpg',
                        link: [
                            {
                                icon: 'http://deansel-bucket.oss-cn-shanghai.aliyuncs.com/gametrigger/swiperImg/bilibili.png',
                                url: 'https://www.bilibili.com/video/BV1aL4y1H7L6/?spm_id_from=333.999.0.0&vd_source=7316ddd4cbfc90a25fa52d5deb6ede22'
                            },
                            {
                                icon: 'http://deansel-bucket.oss-cn-shanghai.aliyuncs.com/gametrigger/swiperImg/taptap.png',
                                url: 'https://www.taptap.cn/app/221062?hreflang=zh_CN'
                            },
                            {
                                icon: 'http://deansel-bucket.oss-cn-shanghai.aliyuncs.com/gametrigger/swiperImg/youtobe.png',
                                url: 'https://www.youtube.com/watch?v=vTPq-9k0m3A'
                            }
                        ]
                    }
                ],
                english: [
                    {
                        title: 'Karma: the Dark World',
                        text: `Karma: the Dark World is a first-person psychological horror game. As a Roam operative, you will travel through different periods of time and wander through other people's minds. Uncover the truth behind the events and work to find the root cause of it all. Developed by Pollard Studio, the team members have previously worked on and contributed to games such as "Killzone," "God of War," "The Dark Pictures Anthology," "Horizon Forbidden West," and "COD," and are responsible for the art, game design, and programing aspects of these games.`,
                        pcImg: 'http://deansel-bucket.oss-cn-shanghai.aliyuncs.com/gametrigger/swiperImg/karma_shu.jpg',
                        mobileImg: 'http://deansel-bucket.oss-cn-shanghai.aliyuncs.com/gametrigger/swiperImg/karma_heng.jpg',
                        link: [
                            {
                                icon: 'http://deansel-bucket.oss-cn-shanghai.aliyuncs.com/gametrigger/swiperImg/bilibili.png',
                                url: 'https://space.bilibili.com/211072?spm_id_from=333.337.search-card.all.click'
                            },
                            {
                                icon: 'http://deansel-bucket.oss-cn-shanghai.aliyuncs.com/gametrigger/swiperImg/steam.png',
                                url: 'https://store.steampowered.com/app/1376200/The_Dark_World_KARMA/ '
                            },
                            {
                                icon: 'http://deansel-bucket.oss-cn-shanghai.aliyuncs.com/gametrigger/swiperImg/youtobe.png',
                                url: 'https://www.youtube.com/watch?v=5Ga9LF2dFE0'
                            }
                        ]
                    },
                    {
                        title: 'Reverse: 1999',
                        text: `Reverse: 1999 is a 20th century occult-themed strategy RPG developed by Bluepoch. As the timekeeper, you will shuttle through various eras from 1900 to 2000 help mystics from different countries to escape the “storm” and explore the truth of the world. Please enjoy the whole process of filmic English performance and retro aesthetics.`,
                        pcImg: 'http://deansel-bucket.oss-cn-shanghai.aliyuncs.com/gametrigger/swiperImg/reverse_heng.jpg',
                        mobileImg: 'http://deansel-bucket.oss-cn-shanghai.aliyuncs.com/gametrigger/swiperImg/reverse_shu.jpg',
                        link: [
                            {
                                icon: 'http://deansel-bucket.oss-cn-shanghai.aliyuncs.com/gametrigger/swiperImg/bilibili.png',
                                url: 'https://www.bilibili.com/video/BV1aL4y1H7L6/?spm_id_from=333.999.0.0&vd_source=7316ddd4cbfc90a25fa52d5deb6ede22'
                            },
                            {
                                icon: 'http://deansel-bucket.oss-cn-shanghai.aliyuncs.com/gametrigger/swiperImg/taptap.png',
                                url: 'https://www.taptap.cn/app/221062?hreflang=zh_CN'
                            },
                            {
                                icon: 'http://deansel-bucket.oss-cn-shanghai.aliyuncs.com/gametrigger/swiperImg/youtobe.png',
                                url: 'https://www.youtube.com/watch?v=vTPq-9k0m3A'
                            }
                        ]
                    }
                ]
            }
        }
    },
    methods: {
        switchF(v) {
            this.now = v
            this.language = this.languageData[v]
            this.language.section_3 = this.section_3[v]
            this.$nextTick( () => {
                const swiper = document.querySelector('.swiper-container').swiper
                swiper.loopDestroy(); //销毁循环分页
                swiper.loopCreate(); //创建循环分页
                swiper.update(); //更新样式
            })
            
        },
        htmlTopPosition (obj) {
            var o = obj
            var t = o.offsetTop
            while (o.offsetParent) {
                o = o.offsetParent
                t += o.offsetTop
            }
            return t
        },
        prev() {
            console.log('123')
        },
        onPageResize () {
            let self = this
            let arr = []
            this.scrollItems.forEach((item) => {
                arr.push(self.htmlTopPosition(item))
            })
            // console.log('3333333333', arr)
            this.offsetTops = arr
            console.log(arr)
            this.onPageScroll()
        },
        onChangeNav (index) {
            this.navIndex = index
            this.$nextTick(() => {
                document.querySelector(`#section_${index}`).scrollIntoView({
                    behavior: "smooth", // 平滑过渡
                    block: "start" // 上边框与视窗顶部平齐。默认值
                })
            })
        },
        onChangeSideNav (index) {
            this.showSideNav = false
            this.onChangeNav(index)
        },
        onOpenSideNav () {
            this.showSideNav = true
        },
        onCloseSideNav (e) {
            if (e.target.id === 'sidenavContainer') {
                this.showSideNav = false
            }
        },
        onPageScroll () {
            let range = 40
            let top = document.body.scrollTop || document.documentElement.scrollTop
            if (top > this.offsetTops[4] - range) {
                this.navIndex = 4
            } else if (top > this.offsetTops[3] - range) {
                this.navIndex = 3
            }else if (top > this.offsetTops[2] - range) {
                this.navIndex = 2
            } else if (top > this.offsetTops[1] - range) {
                this.navIndex = 1
            } else {
                this.navIndex = 0
            }
        },
        getSwiper() {
            let formData = {
                data: ''
            }
            console.log(formData)
            request('/gametrigger/imageInfo/queryInfo' ,
                {
                    method: 'POST',
                    // credentials: 'include',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(formData)
                }
            ).then((response) => {
                if (response.status == 200) {
                    return response.json()
                } else {
                    console.log('服务器错误')
                }
            }).then((json) => {
                let cn = []
                let en = []
                for (let i = 0; i < json.data.data.length; i++) {
                    let iconArr = []
                    for (let j = 0; j < json.data.data[i].icons.length; j++) {
                        let iconObj = {
                            icon: json.data.data[i].icons[j].iconUrl,
                            url: json.data.data[i].icons[j].jumpUrl
                        }
                        iconArr.push(iconObj)
                    }
                    let cnObj = {
                        title: json.data.data[i].cnTitle,
                        text: json.data.data[i].cnContent,
                        pcImg: json.data.data[i].horizontalUrl,
                        mobileImg: json.data.data[i].verticalUrl,
                        link: iconArr
                    }
                    let enObj = {
                        title: json.data.data[i].enTitle,
                        text: json.data.data[i].enContent,
                        pcImg: json.data.data[i].horizontalUrl,
                        mobileImg: json.data.data[i].verticalUrl,
                        link: iconArr
                    }
                    cn.push(cnObj)
                    en.push(enObj)
                }
                this.section_3.chinese = cn
                this.section_3.english = en
                this.language.section_3 = this.section_3[this.now]
                console.log(json.data.data[0])
            }).catch((error) => {
                console.log(error)
            })
        }
    },
    mounted () {
        window.onscroll = this.onPageScroll
        window.onresize = this.onPageResize
        setTimeout(() => {
            this.$nextTick(function () {
                this.scrollItems = document.querySelectorAll('.section')
                this.onPageResize()
                let mySwiper = new Swiper('.swiper-container', {
                    loop: true,
                    // observer:true,
                    pagination: {
                        el: '.swiper-pagination',
                        clickable: true,
                    },
                    navigation: {
                        prevEl: '.swiper-button-prev',
                        nextEl: '.swiper-button-next'
                    },
                    // onSlideChangeEnd: function(swiper){
                    //     swiper.update();
                    //     mySwiper.startAutoplay();
                    //     mySwiper.reLoop();
                    // }
                    // paginationClickable:true,
                    // spaceBetween: 60,
                    // autoplay: {
                    //     delay: 2500,
                    //     // 拖动或滑动后停止
                    //     disableOnInteraction: false
                    // }
                })
                console.log(mySwiper)
            })
        }, 0);
        this.getSwiper()
        // 稍后根据浏览器语言设置默认语言
        const lang = window.navigator.language || window.navigator.userLanguage
        let langCode = lang.includes('zh') ? 'chinese' : 'english'
        this.switchF(langCode)
    },
    onload () {
    }
    
}
</script>

<style scoped>
.home {
    width: 100vw;
    min-height: 100vh;
}
.nav {
    width: 80vw;
    height: 80px;
    background: rgba(0, 0, 0, 0.5);
    position: fixed;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 99;
}
.logo {
    height: 100%;
}
.navbox {
    min-width: 600px;
    width: 48vw;
    height: 100%;
    font-size: 1.125rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.navitem {
    color: #fff;
    position: relative;
    white-space: pre-wrap
}
.navitem:hover {
    cursor: pointer;
}
.navitem_active {
    color: #C91721;
}
.navitem_active2 {
    color: #C91721;
}
.navitem_active::after {
    display: block;
    content: '';
    width: 0;
    border-width: 8px 8px 8px 8px;
    border-style: solid;
    border-color:transparent transparent #C91721 transparent;
    position: absolute;
    bottom: -15px;
    left: 50%;
    transform: translateX(-50%);
}
.nav_mobile {
    display: none;
}
.navIcon {
    width: 30px;
    height: 30px;
    position: fixed;
    z-index: 90;
    top: 30px;
    left: 24px;
}
.navIconImg {
    vertical-align: middle;
    width: 100%;
}
.navIcon:hover {
    cursor: pointer;
}
.nav_mobile_logo {
    height: 30px;
    position: absolute;
    z-index: 90;
    top: 28px;
    left: 68px;
}
.sidenavContainer {
    width: 100vw;
    height: 100vh;
    position: fixed;
    z-index: 99;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.5);
}
.sidenav {
    position: absolute;
    top: 0;
    left: 0;
    width: 30vw;
    min-width: 150px;
    height: 100vh;
    background: #252525;
    color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.side_logo {
    width: 56%;
    margin: 0 auto;
}
.sidenavBox {
    width: 100%;
    text-align: center;
    line-height: 2;
    font-size: 1.125rem;
    margin-top: 5%;
}
.sidenavBox div {
    width: 100%;
    height: 80px;
    /* line-height: 80px; */
    background: #181818;
    margin: 20% 0;
    display: flex;
    justify-content: center;
    align-items: center;
}
.sidenavitem_active {
    color: #C91721;
}
.sidenavitem_active::after {
    display: block;
    content: '';
    width: 0;
    border-width: 8px 8px 8px 8px;
    border-style: solid;
    border-color:transparent transparent #C91721 transparent;
    position: absolute;
    bottom: 12px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 99;
}
.page {
    width: 100vw;
    min-width: 360px;
    min-height: 100vh;
    overflow: hidden;
}
.bg_home {
    background-image: url('https://deansel-bucket.oss-cn-shanghai.aliyuncs.com/gametrigger/bg_home.jpg');
    background-size: cover;
    background-position: center;
}
.home_logo {
    width: 45vw;
}
.home_title {
    width: 46vw;
    height: 4.8vw;
    object-fit: contain;
    margin: 4% 0;
}
.home_words {
    width: 46vw;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.home_words img {
    height: 4vw;
}
.section {
    width: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 5%;
}
.section:nth-child(1) {
    margin-top: 0;
}
.title {
    /* width: 9.75rem; */
    font-weight: 500;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 7vw;
    margin-top: 8%;
}
.title_top {
    width: 100%;
    font-size: 1.75rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.title_top img {
    width: 0.687rem;
    height: 1.625rem;
}
.title_bottom {
    font-size: 0.75rem;
    letter-spacing: 0.25em;
    text-indent: 0.25em;
}
.content {
    width: 64vw;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 5%;
}
.content_reverse {
    width: 64vw;
    display: flex;
    flex-direction: row-reverse;
    justify-content: center;
    align-items: center;
}
.content_reverse_pc {
    width: 64vw;
    display: flex;
    flex-direction: row-reverse;
    justify-content: center;
    align-items: center;
}
.content_reverse_mobile {
    width: 100vw;
    display: flex;
    flex-direction: row-reverse;
    justify-content: center;
    align-items: center;
}
.content_img {
    width: 30vw;
    height: 24vw;
    object-fit: cover;
}
.content .content_img {
    margin-right: 4vw;
}
.content_reverse .content_img {
    margin-left: 4vw;
}
.textbox {
    width: 30vw;
    font-size: 1.125rem;
    text-align: left;
}
.text_title {
    display: flex;
    align-items: center;
    margin-bottom: 3%;
}
.text_title_2 {
    font-size: 1.625rem;
    margin-bottom: 8%;
    font-weight: 700;
}
.text_title span{
    font-size: 1.625rem;
    font-weight: 500;
    display: inline-block;
}
.spot {
    width: 0.5rem;
    height: 0.5rem;
    display: inline-block;
    border-radius: 50%;
    background: #C91721;
    margin-right: 1.25rem;
    vertical-align: middle;
}
.text {
    margin-bottom: 6%;
    color: rgba(255, 255, 255, 0.8);
}
.text:nth-last-child() {
    margin-bottom: 0;
}
.contact_box {
    min-width: 64vw;
    max-width: 90vw;
    min-height: 23vw;
    border: #fff solid 1px;
    position: relative;
    display: flex;
    align-items: center;
}
.left_out_border {
    width: 5vw;
    height: 5vw;
    border-top: #fff solid 1px;
    border-left: #fff solid 1px;
    position: absolute;
    left: -1vw;
    top: -1vw;
}
.right_out_border {
    width: 5vw;
    height: 5vw;
    border-bottom: #fff solid 1px;
    border-right: #fff solid 1px;
    position: absolute;
    right: -1vw;
    bottom: -1vw;
}
.left_box {
    width: 6em;
    text-align: left;
    font-size: 3.625rem;
    font-weight: 700;
    margin: 5vw 0 5vw 5vw;
}
.icon_engine {
    width: 7.18rem;
    height: 3.125rem;
    margin-top: 1.5rem;
}
.middle_line {
    height: 16vw;
    width: 1px;
    background: rgba(255, 255, 255, 0.6);
    margin: 0 4vw 0 3vw;
}
.right_box {
    text-align: left;
    margin-right: 10vw;
}
.contact_title {
    font-size: 1.25rem;
    margin-bottom: 3%;
}
.mail {
    font-size: 1.75rem;
    color: #C91721;
    text-decoration: none;
    display: block;
    margin-bottom: 6%;
}
.mail:nth-last-child(1) {
    margin-bottom: 0;
}
.bottom {
    width: 70%;
    margin: 10%;
    margin-bottom: 5%;
    font-size: 1rem;
    color:rgba(255, 255, 255, 0.5);
    line-height: 1.5;
}
.swiper_for {
    display: flex;
    justify-content: center;
    align-items: center;
    background-size: 100%;
    box-sizing: border-box;
    border: 2px solid #000;
}
.swiper_for2 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-size: 100%;
}
.swiper_for::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    z-index: 1;
}
.swiper_L {
    width: 22vw;
    height: 21vw;
    padding: 1vw 2vw;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    z-index: 2;
}
.swiper_L2 {
    width: 80vw;
    height: 70vw;
    margin-top: 2vw;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* z-index: 2; */
}
.swiper_title {
    font-weight: 700;
    font-size: 1.15rem;
    text-align: left;
    margin-bottom: 1vw;
}
.swiper_text {
    font-size: 0.95rem;
    text-align: left;
    margin-bottom: 1vw;
    text-align:justify;
}
.swiper_R {
    width: 30vw;
    height: 20vw;
    background-size: 100%;
    z-index: 2;
    border: 1px solid #fff;
    /* background-color: burlywood; */
}
.swiper_R2 {
    width: 75vw;
    height: 100vw;
    background-size: 100%;
    /* z-index: 2; */
    border: 1px solid #fff;
    /* background-color: burlywood; */
}
.swiper_link {
    width: 1.5vw;
    height: 1.5vw;
    background-size: 100%;
    display: inline-block;
    margin-right: 15px;
}
.swiper_link2 {
    width: 5.5vw;
    height: 5.5vw;
    background-size: 100%;
    display: inline-block;
    margin-right: 15px;
}
.content_reverse_mobile {
    display: none;
}
@media screen and (max-width: 1200px) {
    .content_reverse_pc {
        display: none;
    }
    .content_reverse_mobile {
        display: block;
    }
}
@media screen and (max-width: 992px) {
    .left_box {
        font-size: 3rem;
    }
    .icon_engine {
        width: 6rem;
        height: 2.5rem;
    }
    .contact_title {
        font-size: 1.25rem;
    }
    .mail {
        font-size: 1.5rem;
    }
}
@media screen and (max-width: 768px) {
    .content_reverse_pc {
        display: none;
    }
    .content_reverse_mobile {
        display: block;
    }
    .nav {
        display: none;
    }
    .nav_mobile {
        display: block;
    }
    .home_logo {
        width: 58vw;
    }
    .home_title {
        width: 60vw;
        height: 6.3vw;
        margin: 4% 0;
    }
    .home_words {
        width: 60vw;
    }
    .home_words img {
        height: 6vw;
    }
    .content {
        flex-direction: column;
    }
    .content_reverse {
        flex-direction: column;
    }
    .content_img {
        width: 100%;
        height: 40vw;
    }
    .content .content_img {
        margin-right: 0;
        margin-bottom: 5vw;
    }
    .content_reverse .content_img {
        margin-left: 0;
        margin-bottom: 5vw;
    }
    .textbox {
        width: 100%;
    }
    .contact_box {
        min-width: 64vw;
        max-width: 80vw;
        min-height: 23vw;
        border: #fff solid 1px;
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }
    .left_out_border {
        left: -1.5vw;
        top: -1.5vw;
    }
    .right_out_border {
        right: -1.5vw;
        bottom: -1.5vw;
    }
    .middle_line {
        height: 1px;
        width: 56vw;
        background: rgba(255, 255, 255, 0.6);
        margin: 3vw 4vw 3vw 4vw;
    }
    .left_box {
        width: 56vw;
        text-align: left;
        margin: 4vw 4vw 0 4vw;
    }
    .right_box {
        width: 56vw;
        text-align: left;
        margin: 4vw 4vw 5vw 4vw;
    }
    .contact_title {
        margin-bottom: 2%;
    }
    .mail {
        margin-bottom: 4%;
    }
}
@media screen and (max-width: 450px) {
    .home_logo {
        width: 68vw;
    }
    .home_title {
        width: 70vw;
        height: 7.3vw;
        margin: 4% 0;
    }
    .home_words {
        width: 70vw;
    }
    .home_words img {
        height: 8vw;
    }
    .content {
        width: 70vw;
    }
    .content_reverse {
        width: 70vw;
    }
    .contact_box {
        width: 70vw;
        min-width: 252px;
    }
    .left_out_border {
        left: -2vw;
        top: -2vw;
    }
    .right_out_border {
        right: -2vw;
        bottom: -2vw;
    }
    .middle_line {
        width: 62vw;
    }
    .left_box {
        width: 62vw;
    }
    .right_box {
        width: 62vw;
    }
}
</style>
